<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainForm from "@/components/MainForm.vue";
import FormTitle from "@/components/FormTitle.vue";
export default {
	components: {
		SecondaryButton,
		MainForm,
		FormTitle,
	},
	data() {
		return {
			listed: false,
			showForm: false,
			action: "Agregar",
			faq: {
				id: { name: "Id", value: null },
				question: { name: "Pregunta", value: null },
				answer: { name: "Respuesta", value: null },
				active: { name: "Activo", value: true },
			},
		};
	},
	created() {
		this.SET_LOADING(true);
		this.listed = false;
		this.loadFaqs();
	},
	computed: {
		...mapState("loading", ["isLoading"]),
		...mapState("faqsStore", ["faqs", "error"]),
	},
	methods: {
		...mapMutations("loading", ["SET_LOADING"]),
		...mapMutations("faqsStore", ["SET_FAQ_ITEM", "SET_ERROR"]),
		...mapActions("faqsStore", ["faqsActions"]),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		toggleForm() {
			this.showForm = !this.showForm;
		},
		async loadFaqs() {
			await this.faqsActions("getFaqs");
			this.listed = true;
			this.SET_LOADING(false);
		},
		update(faq) {
			this.SET_ERROR(null);
			this.toggleForm();
			this.action = "Actualizar";
			for (let f in this.faq) {
				this.faq[f]["value"] = faq[f];
			}
		},
		remove(id) {
			this.SET_ERROR(null);
			this.SET_LOADING(true);

			this.$swal({
				title: "¿Desea eliminar esta Pregunta Frecuente?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.SET_FAQ_ITEM({ id });
					this.faqsActions("deleteFaq").then(() => {
						if (!this.error) {
							this.loadFaqs();
							this.$swal({
								icon: "success",
								title: `La Pregunta Frecuente ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});

			this.SET_LOADING(false);
		},
		async submit() {
			this.SET_ERROR(null);
			this.SET_LOADING(true);
			let faq = this.faq;
			let output = {};

			for (let f in faq) {
				if (faq[f].value) output[f] = faq[f].value;
			}

			this.SET_FAQ_ITEM(output);

			if (this.action == "Agregar") {
				await this.faqsActions("createFaq");
				if (!this.error) {
					this.loadFaqs();
					this.toggleForm();
					this.$swal({
						icon: "success",
						title: `La Pregunta Frecuente ha sido creado con éxito.`,
						showConfirmButton: false,
						timer: 3000,
					});
					this.clear();
				}
			} else {
				await this.faqsActions("updateFaq");
				if (!this.error) {
					this.loadFaqs();
					this.toggleForm();
					this.$swal({
						icon: "success",
						title: `La Pregunta Frecuente se actualizó con éxito.`,
						showConfirmButton: false,
						timer: 3000,
					});
					this.clear();
				}
			}

			this.SET_LOADING(false);
		},
		clear() {
			this.SET_ERROR(null);
			for (let f in this.faq) {
				this.faq[f].value = null;
			}
			this.faq.active.value = true;
			this.action = "Agregar";
		},
	},
};
</script>

<template>
	<div class="main">
		<div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>
				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6"
				/>
				<form-title
					:action="action"
					text="Preguntas"
					textBold="Frecuentes"
					name=""
				></form-title>
				<!-- DISPLAY FORM -->
				<form class="grid grid-cols-2 gap-5 items-center">
					<label
						class="col-span-2 text-lg"
						:for="key"
						v-for="(f, key, index) in faq"
						:key="key"
						v-show="key != 'id' && key != 'active'"
						ref="labels"
					>
						<textarea
							:ref="index"
							:required="key != 'id' && key != 'active'"
							class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
							:name="key"
							:placeholder="f.name"
							v-model="f.value"
							rows="2"
						/>
					</label>
					<span
						v-if="error"
						class="block mt-5 red text-center b col-span-2"
						>{{ error }}</span
					>
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						<SecondaryButton
							v-if="action == 'Actualizar'"
							@click.native="remove(faq.id.value)"
							text="Eliminar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							@click.native="[toggleForm(), clear()]"
							v-else
							text="Cancelar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							:text="action"
							@click.native="submit"
							class="flex-none"
						/>
					</div>
				</form>
			</div>
		</div>
		<main-form
			v-on:toggleForm="toggleForm"
			:action="action"
			text="Preguntas"
			textBold="Frecuentes"
		></main-form>
		<table
			v-if="!isLoading && faqs.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group elektra-bg-red">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						v-for="(field, key) in faq"
						:key="key"
						v-show="key != 'id' && key != 'active'"
					>
						{{ field.name }}
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						faqs.length > 1 ? 'w-full md:w-1/2 lg:w-full' : 'w-full'
					"
					v-for="(f, i) in faqs"
					:key="i"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						:class="{ 'green b': key == 'id' }"
						v-for="(field, key) in faq"
						:key="key"
						v-show="key != 'id' && key != 'active'"
					>
						{{ f[key] }}
					</td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
								class="title-hint"
								aria-label="Da clic aquí para editar esta Pregunta frecuente"
							>
								<font-awesome-icon
									@click="update(f)"
									class="green hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-pen-to-square"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Da clic aquí para eliminar este Pregunta frecuente"
							>
								<font-awesome-icon
									@click="remove(f.id)"
									class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-trash-can"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron Preguntas Frecuentes</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "Pregunta", 2 "Respuesta";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i + 1}):before {
			content: $field;
		}
	}
}
</style>
